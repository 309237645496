export const constantMenus = [
  {
    name: '资金中心',
    key: 'money',
    children: [
      {
        path: '/moneycenter/accountBalance',
        name: '余额账户'
      },
      {
        path: '/moneycenter/topupAmount',
        name: '充值'
      },
      {
        path: '/moneycenter/withdrawMoney',
        name: '提现'
      },
      {
        path: '/moneycenter/paymentDetails',
        name: '收支明细'
      },
      {
        path: '/moneycenter/lockedDetails',
        name: '锁定明细'
      }
    ]
  },
  {
    name: '买家中心',
    key: 'buyer',
    children: [
      {
        path: '/buyercenter/purchaseOrder',
        name: '采购管理',
        homeIcon: 'purchase',
        isHome: true
      },
      {
        path: '/buyercenter/pack',
        name: '竞价管理'
      },
      {
        path: '/buyercenter/transport',
        name: '招标管理'
      },
      {
        path: '/buyercenter/deliveryOrder',
        name: '提单管理',
        homeIcon: 'bill',
        isHome: true
      },
      {
        path: '/buyercenter/c2m',
        name: '加工管理',
        isHidden: true
      },
      {
        path: '/buyercenter/invoiceMng',
        name: '发票管理',
        homeIcon: 'invoice',
        isHome: true
      }
    ]
  },
  {
    name: '卖家中心',
    key: 'seller',
    children: [
      {
        path: '/sellercenter/remanent',
        name: '商品管理',
        homeIcon: 'goods',
        isHome: true
      },
      {
        path: '/sellercenter/commodity',
        name: '竞价管理'
      },
      {
        path: '/sellercenter/transport',
        name: '招标管理',
        isHidden: true
      },
      {
        path: '/sellercenter/salesOrder',
        name: '销售管理',
        homeIcon: 'sale',
        isHome: true
      },
      {
        path: '/sellercenter/deliveryOrder',
        name: '提单管理',
        homeIcon: 'bill',
        isHome: true
      },
      {
        path: '/sellercenter/dataAnalysis',
        name: '数据中心',
        isHidden: true
      }
    ]
  },
  {
    name: '加工中心',
    key: 'process',
    children: [
      {
        path: '/processcenter/demand',
        name: '询单报价'
      },
      {
        path: '/processcenter/order',
        name: '合同管理'
      },
      {
        path: '/processcenter/delivery',
        name: '发货管理'
      }
    ]
  }
]

export const constantCorpMenus = [
  {
    name: '公司资料',
    path: '/usercenter/company',
    icon: 'icon-menu-company'
  },
  {
    name: '员工管理',
    path: '/usercenter/staff',
    icon: 'icon-menu-member'
  },
  {
    name: '资金中心',
    path: '/moneycenter/accountBalance',
    icon: 'icon-menu-money'
  }
]

export const helpcenterMenus = [
  {
    name: '平台规则',
    menuIcon: 'icon-pingtaiguize-moren',
    key: 'rules',
    children: [
      { name: '平台交易规则', path: '/trendRule' },
      { name: '联营卖家管理规范', path: '/sellerRule' },
      { name: '注册协议', path: '/registRule' },
      { name: '隐私条款', path: '/privacyRule' },
      { name: '销售合同模板', path: '/saleTemplate' },
      { name: '云加工交易规则', path: '/c2mRule' }
    ]
  },
  {
    name: '操作指南',
    menuIcon: 'icon-caozuozhinan-moren',
    key: 'guides',
    children: [
      {
        name: '买家指南',
        path: '/buyerGuide',
        key: 'guides-buyer',
        children: [
          {
            name: '注册',
            path: '/buyerGuide/regist'
          },
          {
            name: '会员认证',
            path: '/buyerGuide/auth'
          },
          {
            name: '购买商品',
            path: '/buyerGuide/buy'
          },
          {
            name: '打包竞价',
            path: '/buyerGuide/pack'
          },
          {
            name: '单条竞价',
            path: '/buyerGuide/single'
          },
          {
            name: '预约提货',
            path: '/buyerGuide/delivery'
          },
          {
            name: '查看发票',
            path: '/buyerGuide/invoice'
          },
          {
            name: '售后服务',
            path: '/buyerGuide/afterSale'
          },
          {
            name: '加工需求',
            path: '/buyerGuide/demand'
          },
          {
            name: '加工订单',
            path: '/buyerGuide/order'
          },
          {
            name: '加工预约',
            path: '/buyerGuide/applyC2M'
          },
          {
            name: '加工实提',
            path: '/buyerGuide/deliveryC2M'
          }
        ]
      },
      {
        name: '卖家指南',
        path: '/sellerGuide',
        key: 'guides-seller',
        children: [
          {
            name: '商家入驻',
            path: '/sellerGuide/merchant'
          },
          {
            name: '商品挂牌',
            path: '/sellerGuide/goodList'
          },
          {
            name: '发布竞价',
            path: '/sellerGuide/bid'
          }
        ]
      },
      {
        name: '加工指南',
        path: '/processGuide',
        key: 'guides-process',
        children: [
          {
            name: '工厂申请',
            path: '/processGuide/apply'
          },
          {
            name: '询单报价',
            path: '/processGuide/demand'
          },
          {
            name: '合同管理',
            path: '/processGuide/order'
          },
          {
            name: '发货管理',
            path: '/processGuide/delivery'
          }
        ]
      }
    ]
  },
  {
    name: '支付方式',
    menuIcon: 'icon-zhifufangshi-moren',
    key: 'mode',
    children: [
      { name: '网银支付', path: '/internetbank' },
      { name: '承兑支付', path: '/acceptPay' }
    ]
  },
  {
    name: '公司信息',
    menuIcon: 'icon-gongsixinxi-moren',
    path: '/company'
  },
  {
    name: '联系我们',
    menuIcon: 'icon-lianxiwomen-moren2',
    key: 'contact',
    children: [
      { name: '客服电话', path: '/contact/service' },
      { name: '销售电话', path: '/contact/salesman' },
      { name: '仓库电话', path: '/contact/warehouse' }
    ]
  }
]

export const homeBarMenus = [
  {
    name: '首页',
    path: '/'
  },
  {
    name: '现货商城',
    path: '/shop'
  },
  {
    name: '竞价专区',
    children: [
      {
        name: '打包竞价',
        path: '/bid/pack'
      },
      {
        name: '单条竞价',
        path: '/bid/single'
      }
    ]
  },
  {
    name: '运输招标',
    path: '/transport'
  },
  {
    name: '云加工',
    path: '/c2m',
    icon: 'menu-new'
  },
  {
    name: '循环物资',
    path: '',
    link: ''
  },
  {
    name: '资讯中心',
    path: '/news'
  },
  {
    name: '特钢网',
    path: '',
    link: 'https://www.tegang365.com/'
  }
]
